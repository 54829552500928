import React from 'react';
import { Layout, SEO } from 'components/common';
import { Intro, Skills, Contact, Projects } from 'components/landing';

export default () => (
  <Layout>
    <h3>Políticas de privacidad </h3>
    <p>
    En el presente se establecen los términos en los que “GUAPPE” E.I.R.L usa y protege la información que los usuarios le proporcionan al momento de utilizar su aplicación móvil; descargable a través de Google (Play Store) y Apple (App Store). La compañía se compromete a salvaguardar la seguridad de los datos que sean proporcionados por los usuarios. Esta Política de Privacidad puede cambiar con el paso del tiempo, o ser actualizada, para lo cual será puesto de igual manera a su disposición todos los cambios o ajustes que se realicen en las mismas; estará disponible con el fin de que usted pueda asegurarse de que se encuentra de acuerdo con los cambios, aceptando así las nuevas políticas; por lo que le recomendamos siempre al usuario estar atento a las posibles comunicaciones a través de correo electrónico o mensaje de texto (SMS). Nos hacemos cargo de poner a su entera disposición estos cambios, y le sugerimos que visite nuestro sitio web periódicamente, donde podrá visualizar el historial de estos cambios, a través de la página www.GUAPPE.com 
    </p>
    <p>
    Para los efectos del siguiente documento se tendrá en consideración el uso de los siguientes términos: GUAPPE (Identificará a la empresa GUAPPE E.I.R.L) Usuario (Será identificado como usuario cada quien que esté registrado dentro de la aplicación, haga uso o no de los servicios) Plataforma (Se identificará como plataforma a todo el sistema que incluye la aplicación “GUAPPE”) Datos Personales (Se identificarán como datos personales a cualquier información que sea originaria del Usuario y que sean de carácter sensible para el mismo).

        1. Información que se obtiene de la aplicación y su utilización

        a. Información que proporciona el usuario

    La aplicación obtiene la información que usted proporciona al registrarse dentro de la plataforma, ya que deberá proporcionar ciertos datos de carácter personal que serán utilizados únicamente para el propósito que fueron recopilados. 
    </p>
    <p>
    Al momento de registrarse dentro de la aplicación, y para poder hacer uso de la misma, deberá proporcionar (a) dirección de correo electrónico, (b) contraseña (c) domicilio particular (d) número de teléfono, otros que puedan ser solicitados para el correcto uso de la plataforma, datos de (e) geolocalización, para garantizar el correcto y satisfactorio uso del servicio. 
    </p>
    <p>
    El Usuario a través de la aceptación de las Políticas de Privacidad declara que autoriza a GUAPPE a enviarle información o avisos a su correo electrónico o mensajes de texto (SMS) a su teléfono celular sobre publicidad/promociones que puedan ser de su interés, o información general sobre la aplicación. 
    </p>
    <p>
    Así como también acepta que GUAPPE revele, comparta o transfiera,     total o parcialmente, sus Datos Personales e Información personal con su matriz, personas relacionadas o empresas afiliadas o relacionadas con GUAPPE, a cualquier título y en el momento, forma y condiciones que estime pertinente. Entiéndase también que estos datos podrán ser otorgados a los repartidores y restaurantes para el correcto funcionamiento del servicio. 
    </p>
        b. De los deberes que asumen los usuarios sobre los datos 

    <p>Los Usuarios asumen el compromiso de indicar en el Registro sus Datos Personales de manera exacta, precisa y verdadera, así como también la obligación de actualizar sus datos personales cada que ocurra una modificación de los mismos.

    GUAPPE se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos Personales indicados en el Registro y de suspender temporal o definitiva al Usuario cuyos datos no hayan podido ser confirmados. 

    Asimismo, durante el uso de la Plataforma, los Usuarios proporcionarán a GUAPPE información relativa a sus intereses, gustos, contactos, incluyendo la grabación de los llamados que los Usuarios puedan realizar al servicio de soporte técnico de la Plataforma. Así como también se podrán usar dichas grabaciones, así como las comunicaciones a través de correos electrónicos, whatsapp y mensaje de texto (SMS) como medio de prueba ante algún litigio que tenga como parte a GUAPPE. Por lo cual, el Usuario se hace responsable por todo lo que diga a través de estos medios.

    El Usuario reconoce que será el único responsable de todos los actos que tengan lugar mediante el uso de su Cuenta, lo que incluye hacerse cargo del pago de las tarifas que eventualmente se devenguen y de los perjuicios que puedan sufrir otros Usuarios y/o terceros por tal motivo.

    Por lo cual, el usuario deberá salvaguardar su cuenta para así evitar que se haga el uso indebido de la misma. Solo en caso de que pudiera demostrarse que hubo intervención de algún tipo, y bajo una responsabilidad de resolverlo ante litigio correspondiente GUAPPE se podrá hacer responsable de cargos efectuados en su cuenta sin su autorización, para lo cual GUAPPE podrá solicitar toda la información necesaria por parte del Usuario. 
    </p>



        c.  Protección de datos personales
    <p>
    Como principio general, esta aplicación no compartirá ni revelará bajo ningún concepto la información obtenida, excepto cuando haya sido autorizada por usted, o en los siguientes casos: a) Cuando le sea requerido por una autoridad competente y previo el cumplimiento del trámite legal correspondiente y b) Cuando a juicio de esta aplicación sea necesario para hacer cumplir las condiciones de uso y demás términos de la aplicación (ver términos y condiciones) o para salvaguardar la integridad de los demás usuarios de la aplicación. 
    </p>

    <p>
    Vale destacar que si, usted voluntariamente revela información personal o información sensible en línea, en un área pública, esa información puede ser recogida y usada por terceros. Nosotros no controlamos dichas acciones y por lo tanto no nos hacemos responsables ante estas actuaciones. 

    Asimismo se le indica que, podremos utilizar la información que nos ha facilitado a través de su registro para comunicarnos con usted de vez en cuando, para ofrecerle información importante, tales como noticias, y promociones. Usted podrá limitar o detener la información de publicidad y promociones que le lleguen a través de estos medios, esto deberá hacerse a través de una solicitud formal por el correo GUAPPE@gmail.com, más sin embargo se mantendrá vigente para comunicaciones que por su naturaleza sean de interés del usuario o de la compañía. 
    </p>

    <p>
    GUAPPE se reserva el derecho de comunicar información a entidades o terceros cuando haya motivos suficientes para considerar que la actividad de un Usuario sea sospechosa de intentar o cometer un delito o intentar perjudicar a otras personas. Este derecho será usado por GUAPPE a su entera discreción, cuando lo considere apropiado o necesario para mantener la integridad y seguridad de la comunidad y la de los Usuarios; para hacer cumplir los Términos y Condiciones y las Políticas de Privacidad de su Plataforma, y a efectos de cooperar con el cumplimiento y ejecución de la ley, independientemente que no exista una orden judicial o administrativa al efecto.
    </p>
        d. Información recopilada automáticamente 

    <p>
    La aplicación podrá recopilar cierta información automáticamente, incluyendo, el tipo de dispositivo que utilice (móvil, tablet, entre otros), la dirección IP de su dispositivo, el sistema operativo móvil, el tipo de navegadores de internet que utiliza y la información sobre el modo de usar la aplicación.
    </p>

    <p>
    GUAPPE podrá utilizar la información recopilada para: 

      1. Crear y actualizar la Cuenta del Usuario.
    2. Verificar la identidad del Usuario.
    3. Comprobar la veracidad de los Datos Personales de los Usuarios.
    4. Procesar y/o facilitar el pago de los Servicios (Precio, Tarifa y/o propina)
    5. Permitir al repartidor ubicar al Consumidor que haya efectuado la Solicitud que ha aceptado, a fin de que este pueda prestarle un servicio eficiente y rápido, teniendo acceso a su ubicación en tiempo real.
    6. Permitir la comunicación en tiempo real entre el Consumidor y el repartidor para que pueda comunicar sobre cualquier eventualidad que surja durante la prestación del servicio.
    7. Permitir que el servicio de soporte técnico de la Plataforma pueda contactar a los repartidores y/o Consumidores a fin de que el Servicio sea llevado a cabo de la forma más eficiente y rápida posible.
    8. Realizar pruebas, investigaciones y análisis respecto a la prestación de los Servicios y de los productos adquiridos por los Consumidores.
    9. Enviar a los Usuarios información respecto a promociones y/o publicidad de sus Servicios y de productos de sus Comercios Aliados.
    10. Permitir al repartidor ubicar al Consumidor que haya efectuado la Solicitud que ha aceptado, a fin de que pueda prestar un Servicio eficiente y rápido, teniendo acceso a su ubicación en tiempo real.
    </p>
        e. Uso de Datos Personales 
    <p>
    El Usuario declara que autoriza a GUAPPE a compilar y procesar sus Datos Personales e Información Personal con la finalidad de desarrollar estudios internos sobre los intereses, comportamientos y demografía de los Usuarios de la Plataforma.
    </p>
    Así como también autoriza de que puedan enviarle información o avisos de interés a través de su correo electrónico o por mensaje de texto (SMS) a su celular 
    <p>
    2. Control de su información personal
    </p>
    <p>
    Usted podrá en cualquier momento restringir la recopilación o el uso de la información personal que haya proporcionado a nuestra aplicación. Para ello se le solicitará rellenar un formulario, como el de alta de usuario, en donde podrá indicar por ejemplo no recibir más promociones o publicidad por parte de nosotros.
    </p>
    <p>
    También usted podrá decidir que no reservemos más su información personal dentro de nuestra base de datos, así como solicitar el historial de los datos recopilados de su usuario, y nosotros le brindaremos dicha información de manera gratuita y expedita. 
    </p>
    <p>
    Tenga la seguridad de que no se venderá, cederá ni distribuirán los datos personales que se recopilen a través de nuestra aplicación sin su consentimiento, salvo las excepciones de ley que correspondieren.

    3. Tratamiento de datos de menores de edad 
    </p>
    <p>
    No utilizaremos la aplicación para obtener datos a niños menores de 13 años. Si un padre o tutor tiene conocimiento de que su hijo nos ha proporcionado información sin su consentimiento, él o ella debe ponerse en contacto con nosotros a través del correo GUAPPE@gmail.com en cuanto tenga conocimiento de los hechos, y se procederá a eliminar dicha información de inmediato de nuestros archivos dentro de un plazo razonable. Eximiéndose la empresa de todo cargo o responsabilidad sobre el mismo. 
    </p>
    <p>
    Existen ciertos servicios en la Plataforma, los cuales sólo podrán ser solicitados por mayores de edad. Por ello GUAPPE se reserva el derecho de verificar, por los medios que considere más oportunos, la edad real de cualquier Usuario. 
    </p>
    <p>
    Bajo sospecha o constatación de que un Usuario sea menor de 18 años y de que ha falseado los datos que se requieren para adquirir productos o servicios que requieran la mayoría de edad, GUAPPE podrá denegar dicho servicio o producto, sin derecho a reclamo alguno por parte del Usuario afectado.
    </p>
    <p>
    GUAPPE no se hace responsable por el uso indebido por parte de los Usuarios de la aplicación, siendo estas acciones propias de quien las acciona, así como de responsabilidad propia ante cualquier eventualidad. 
    </p>
    4. Su Consentimiento
    <p>
    Mediante el uso de la aplicación, usted acepta el procesamiento de sus datos en los términos de la presente Política de Privacidad, que podrá ser de vez en cuando modificada por nosotros. 
    </p>
    <p>
    En caso de tener alguna duda respecto a la privacidad de sus datos durante el uso de la aplicación, o si tiene preguntas sobre alguna de nuestras prácticas o tratamiento de sus datos, por favor ponerse en contacto con nosotros a través del correo GUAPPE@gmail.com. 
    </p>
  </Layout>
);
